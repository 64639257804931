import React from 'react'

import { IconContext } from "react-icons";

export const wrapRootElement = ({ element }) => (
  <IconContext.Provider value={{ style: {
      display: 'inline-block',
      fontSize: 'inherit',
      height: '1em',
      overflow: 'visible',
      verticalAlign: '-0.125em',
      width: '1.125em' } }}>
    {element}
  </IconContext.Provider>
)
